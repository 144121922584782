import { EventPayload } from '../../../../../types/GTM/EventPayload';

export const handleButtonClick = (dataLayer: EventPayload[], text: string) => {
  dataLayer?.push({
    event: 'cta_click',
    cta_text: text,
    cta_location: window.location.pathname,
    cta_url: window.location.href,
    cta_hostname: window.location.hostname,
    ctaPosition: 'content',
  });
};
